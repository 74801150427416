import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang = "en",
  meta = [],
  image: metaImage,
  keywords,
  title: metaTitle,
  pathname,
}) {
  const {
    site,
    strapiSiteMetaData: {
      data: { attributes: strapiSiteData },
    },
  } = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
        strapiSiteMetaData {
          data {
            attributes {
              intro
              seoDescription
              seoKeywords
              seoTitle
              logo {
                data {
                  attributes {
                    localFile {
                      url
                    }
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const metaDescription = description || strapiSiteData.seoDescription
  const image =
    (metaImage && metaImage.src) ||
    strapiSiteData.logo.data.attributes.localFile.url
  const title = metaTitle || strapiSiteData.seoTitle

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: "keywords",
            content: keywords || strapiSiteData.seoKeywords,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            property: "og:image",
            content: image,
          },
          {
            property: "og:image:width",
            content: !metaImage
              ? strapiSiteData.logo.data.attributes.width
              : metaImage.width,
          },
          {
            property: "og:image:height",
            content: !metaImage
              ? strapiSiteData.logo.data.attributes.height
              : metaImage.height,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
        ].concat(meta)}
      />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
    </>
  )
}

export default SEO
