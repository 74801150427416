import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"

const ContactUs = ({ data }) => {
  const { apiURL: API_URL = "http://localhost:1337" } = data.site.siteMetadata
  const [formData, setFormData] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)

    if (!(formData.fullName && formData.email && formData.message)) return

    if (formData.hiddenField) return

    try {
      await fetch(`${API_URL}/api/contacts`, {
        method: "POST",
        body: JSON.stringify({ data: formData }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(res => {
        if (!res.ok) throw new Error("An error occured")
        return res
      })
      navigate("/contact/thank-you", { replace: true })
      //   show success message
    } catch (err) {
      window.alert("An error occured") // should be changed for a better alert
      setSubmitting(false)
    }
  }

  const handleInputChange = e => {
    setFormData(v => ({ ...v, [e.target.name]: e.target.value }))
  }

  return (
    <Layout showContact={false}>
      <Seo title="Contact - Doganbros.com" />
      <section id="contact-us" className="section full-section">
        <div className="container mb-6">
          <h3 className="title has-text-primary">Contact Us</h3>
          <div>
            <div className="content block">
              <form method="post" onSubmit={handleSubmit}>
                <div className="field">
                  <label htmlFor="fullName" className="label">
                    Name
                  </label>
                  <div className="control">
                    <input
                      id="fullName"
                      name="fullName"
                      required
                      onChange={handleInputChange}
                      className="input"
                      type="text"
                      placeholder="Your Name..."
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="email" className="label">
                    Email
                  </label>
                  <div className="control">
                    <input
                      id="email"
                      onChange={handleInputChange}
                      name="email"
                      className="input"
                      type="email"
                      required
                      placeholder="Your Email..."
                    />
                  </div>
                </div>
                <input
                  type="text"
                  className="hidden-field"
                  name="hiddenField"
                  tabIndex="-1"
                  onClick={handleInputChange}
                />
                <div className="field">
                  <label htmlFor="message" className="label">
                    Message
                  </label>
                  <div id="message" className="control">
                    <textarea
                      name="message"
                      onChange={handleInputChange}
                      className="textarea"
                      placeholder="Your Message..."
                      required
                    />
                  </div>
                </div>
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      disabled={submitting}
                      type="submit"
                      className="is-primary button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ContactURLQuery {
    site {
      siteMetadata {
        apiURL
      }
    }
  }
`

export default ContactUs
